<template>
    <div class="h-screen w-full bg-img vx-row no-gutter items-center justify-center">
        <div class="w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
                <vx-card>
                    <div slot="no-body" class="full-page-bg-color">
                        <div class="vx-row no-gutter justify-center items-center">
                            <div class="vx-col hidden lg:block lg:w-1/2">
                                <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
                            </div>

                            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                                <div class="p-8 login-tabs-container">
                                    <div class="vx-card__title mb-4">
                                        <h4 class="mb-4">Request Reset Password</h4>
                                        <p>Forgot your password? Let's help you reset it.</p>
                                        <!-- <label for="" v-if="env != 'prod'" class="ms-4 badge bg-label-danger">{{ env
                                            }}</label> -->
                                    </div>

                                    <div>
                                        <form v-on:keyup.enter="reset()">
                                            <vs-input name="email" icon-no-border icon="icon icon-user"
                                                icon-pack="feather" label-placeholder="Email" v-model="email"
                                                class="w-full" v-validate="'required|email|min:3'" />
                                            <span class="text-danger text-sm">{{
                                                errors.first("email")
                                            }}</span>
                                        </form>
                                        <div class="flex flex-wrap justify-between my-5">
                                            <vs-button class="float-right" :disabled="!validateForm"
                                                @click="reset()">Reset</vs-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { uuid } from 'vue-uuid'

export default {
    data() {
        return {
            email: "",
            version: process.env.VUE_APP_GIT_VERSION,
            env: process.env.VUE_APP_ENV,
            hostname: Vue.$cookies.get("hostname-api"),
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any() && this.email != "";
        },
    },
    mounted() {
        if (!this.$cookies.get("device_id")) {
            Vue.$cookies.set("device_id", uuid.v1());
        }
        if (this.$cookies.get("token")) {
            this.$router.push("/");
        }
    },
    methods: {
        reset: function () {
            if (!this.validateForm) {
                this.$vs.notify({
                    color: "danger",
                    title: "Gagal",
                    text: "Lengkapi input",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                this.$vs.loading();
                const payload = {
                    userDetails: {
                        email: this.email,
                    },
                    notify: this.$vs.notify,
                    closeAnimation: this.$vs.loading.close,
                };
                this.$store.dispatch("auth/forgotPasswordAttempt", payload);
            }
        },
    },
}

</script>

<style lang="scss">
#page-login {
    .social-login-buttons {
        .bg-facebook {
            background-color: #1551b1;
        }

        .bg-twitter {
            background-color: #00aaff;
        }

        .bg-google {
            background-color: #4285f4;
        }

        .bg-github {
            background-color: #333;
        }
    }
}
</style>